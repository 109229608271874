import { Helmet } from 'react-helmet-async';
import { sentenceCase } from 'change-case';
import { findIndex } from 'lodash';
import { useState, useCallback, useEffect } from 'react';
import {
  Card,
  Tabs,
  Alert,
  Tab,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Divider,
} from '@mui/material';
import { BOOKING_STATUS_COLORS } from '../theme/palette';
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import {
  TableListHead,
  TableListToolbar,
} from '../sections/@dashboard/bookings';
import BookingModal from '../sections/@dashboard/bookings/Modal';
import axios from '../utils/Axios';
import { TableSkeleton } from '../components/skeleton';
import {
  formatBookingDate,
  formatBookingTime,
  filterDateIsSameOrAfter,
} from '../utils/formatTime';
import { useAuthContext } from '../auth/useAuthContext';
import { getSymbol } from './DashboardAppPage';

const TABLE_HEAD = [
  { id: 'customer.firstName', label: 'Customer', alignRight: false },
  { id: 'startDate', label: 'Date', alignRight: false },
  { id: 'service.name', label: 'Service', alignRight: false },
  { id: 'startDate', label: 'Time', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '', alignRight: false },
];

const TABLE_HEAD_WITH_PRICE = [
  { id: 'customer.firstName', label: 'Customer', alignRight: false },
  { id: 'startDate', label: 'Date', alignRight: false },
  { id: 'service.name', label: 'Service', alignRight: false },
  { id: 'startDate', label: 'Time', alignRight: false },
  { id: 'price', label: 'Price', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '', alignRight: false },
];

function descendingComparator(a, b, orderBy) {
  if (orderBy.includes('.')) {
    const splitMe = orderBy.split('.');
    const first = splitMe[0];
    const second = splitMe[1];

    if (b[first][second] < a[first][second]) {
      return -1;
    }
    if (b[first][second] > a[first][second]) {
      return 1;
    }
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(
  array,
  comparator,
  query,
  filterStatus,
  service = 'all',
  startDateFilter,
  userTimezone
) {
  const stabilizedThis = array.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  array = stabilizedThis.map((el) => el[0]);

  if (query) {
    array = array.filter(
      (booking) =>
        booking.customer.firstName
          .toLowerCase()
          .indexOf(query.toLowerCase()) !== -1
    );
  }

  if (service !== 'all') {
    array = array.filter((booking) => booking.service.name === service);
  }

  if (filterStatus !== 'ALL') {
    array = array.filter((booking) => booking.status === filterStatus);
  }

  if (startDateFilter) {
    array = array.filter((booking) =>
      filterDateIsSameOrAfter(booking.startDate, startDateFilter, userTimezone)
    );
  }

  return array;
}

export default function BookingsPage() {
  const { user } = useAuthContext();

  const [isLoading, setIsLoading] = useState(true);
  const [services, setServices] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [openPopover, setOpenPopover] = useState(null);

  const [isNew, setIsNew] = useState(false);

  const [filterServices, setFilterServices] = useState('all');
  const [startDateFilter, setStartDateFilter] = useState(null);
  const [filterStatus, setFilterStatus] = useState('ALL');
  const [filterName, setFilterName] = useState('');

  const [selectedBookingRow, setSelectedBookingRow] = useState(null);

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('startDate');
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [openModal, setOpenModal] = useState(false);
  const [success, setSuccess] = useState(null);

  const { user: { config } } = useAuthContext();

  const getLengthByStatus = (status) =>
    bookings.filter((item) => item.status === status).length;

  const TABS = [
    {
      value: 'ALL',
      label: 'All',
      color: BOOKING_STATUS_COLORS.ALL,
      count: bookings.length,
    },
    {
      value: 'APPROVED',
      label: 'approved',
      color: BOOKING_STATUS_COLORS.APPROVED,
      count: getLengthByStatus('APPROVED'),
    },
    {
      value: 'PENDING',
      label: 'Pending',
      color: BOOKING_STATUS_COLORS.PENDING,
      count: getLengthByStatus('PENDING'),
    },
    {
      value: 'CANCELED',
      label: 'Canceled',
      color: BOOKING_STATUS_COLORS.CANCELED,
      count: getLengthByStatus('CANCELED'),
    },
    {
      value: 'NO_SHOW',
      label: 'No-Show',
      color: BOOKING_STATUS_COLORS.NO_SHOW,
      count: getLengthByStatus('NO_SHOW'),
    },
    {
      value: 'REJECTED',
      label: 'Rejected',
      color: BOOKING_STATUS_COLORS.REJECTED,
      count: getLengthByStatus('REJECTED'),
    },
    {
      value: 'COMPLETED',
      label: 'Completed',
      color: BOOKING_STATUS_COLORS.COMPLETED,
      count: getLengthByStatus('COMPLETED'),
    },
  ];

  const handleOpenMenu = (event, rowId) => {
    setOpenPopover(event.currentTarget);
    setSelectedBookingRow({ _id: rowId });
  };

  const handleCloseMenu = () => {
    setOpenPopover(null);
  };

  const handleRequestSort = (_, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleFilterServices = (event) => {
    setPage(0);
    setFilterServices(event.target.value);
  };

  const handleFilterStatus = (_, newValue) => {
    setPage(0);
    setFilterStatus(newValue);
  };

  const handleFilterDate = (value) => {
    setPage(0);
    setStartDateFilter(value);
  };

  const handleOpenModal = (row) => {
    setOpenPopover(null);
    setOpenModal(true);
    setSelectedBookingRow(row);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    if (selectedBookingRow != null) {
      setSelectedBookingRow(null);
    }
  };

  const handleResetFilter = () => {
    setPage(0);
    setStartDateFilter(null);
  };

  const createBooking = useCallback(async (bookingData) => {
      const response = await axios.post('/bookings', bookingData);
      const bookingResData = response.data.booking;
      if (bookingResData.isCoveredUnderSub) {
        setBookings([...bookings, bookingResData]);
        setSuccess({ bg: 'success', message: 'Booking created.' });
        setTimeout(() => setSuccess(null), 6000);
      } else {
        setSuccess({
          bg: 'warning',
          message:
            'The booking is created but is not covered in your current subscription plan. Please upgrade to notify the customer.',
        });
        setTimeout(() => setSuccess(null), 6000);
      }
    },
    [bookings, setBookings]
  );

  const getBookings = useCallback(async () => {
    try {
      const [bookingsResponse, servicesResponse, customersResponse] =
        await Promise.all([
          axios.get('/bookings'),
          axios.get('/services'),
          axios.get('/customers'),
        ]);
      setBookings(bookingsResponse.data.bookings);
      setServices(servicesResponse.data.services);
      setCustomers(customersResponse.data.customers);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const updateBooking = useCallback(
    async (status) => {
      if (!selectedBookingRow && !selectedBookingRow._id)
        throw new Error('booking is not selected');
      const bookingId = selectedBookingRow._id;
      const response = await axios.put(`/bookings/${bookingId}`, { status });
      const mutatedBookings = [...bookings];
      const bookingIndex = findIndex(mutatedBookings, { _id: bookingId });
      mutatedBookings.splice(bookingIndex, 1, response.data.booking);
      setBookings(mutatedBookings);
    },
    [bookings, setBookings, selectedBookingRow]
  );

  useEffect(() => {
    getBookings();
  }, []);

  const filteredBookings = applySortFilter(
    bookings,
    getComparator(order, orderBy),
    filterName,
    filterStatus,
    filterServices,
    startDateFilter,
    config.timezone
  );

  return (
    <>
      <Helmet>
        <title>Bookings | {localStorage.getItem("companyName") ?? "FlowBookings"}</title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Bookings
          </Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => {
              setIsNew(true);
              setOpenModal(true);
            }}
          >
            New Booking
          </Button>
        </Stack>

        {success != null && (
          <Alert severity={success.bg} color="info" sx={{ mt: 2, mb: 2 }}>
            {success.message}
          </Alert>
        )}

        <Card>
          <Tabs
            value={filterStatus}
            onChange={handleFilterStatus}
            sx={{
              px: 2,
              bgcolor: 'background.neutral',
            }}
          >
            {TABS.map((tab) => (
              <Tab
                key={tab.value}
                value={tab.value}
                label={tab.label}
                icon={<Label sx={{ mr: 1, ...tab.color }}>{tab.count}</Label>}
              />
            ))}
          </Tabs>

          <Divider />

          <TableListToolbar
            filterName={filterName}
            onFilterName={handleFilterByName}
            services={services}
            onFilterServices={handleFilterServices}
            filterServices={filterServices}
            onFilterDate={handleFilterDate}
            startDateFilter={startDateFilter}
            isDateFiltered={!!startDateFilter}
            onResetFilter={handleResetFilter}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 100 }}>
              <Table>
                <TableListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={user.config.enablePayments ? TABLE_HEAD_WITH_PRICE : TABLE_HEAD}
                  rowCount={0}
                  onRequestSort={handleRequestSort}
                />
                {isLoading && [...Array(5)].map(() => <TableSkeleton />)}
                {!isLoading && filteredBookings.length ? (
                  <TableBody>
                    {filteredBookings
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        const { _id, customer, startDate, status, service, price } =
                          row;
                        return (
                          <TableRow hover key={_id} tabIndex={-1}>
                            <TableCell component="th" scope="row">
                              <Stack
                                direction="column"
                                alignItems="left"
                                spacing={0.5}
                              >
                                <Typography variant="subtitle2" noWrap>
                                  {customer.firstName} {customer.lastName}
                                </Typography>
                                <Typography variant="subtitle3">
                                  {customer.email}
                                </Typography>
                              </Stack>
                            </TableCell>

                            <TableCell align="left">
                              {formatBookingDate(startDate, config.timezone)}
                            </TableCell>
                            <TableCell align="left">{service.name}</TableCell>
                            <TableCell align="left">
                              {formatBookingTime(startDate, config.timezone)}
                            </TableCell>
                            <TableCell align="left">{price ? `${getSymbol(user.config.currency)} ${price}` : 'Free'}</TableCell>
                            <TableCell align="left">
                              <Label
                                sx={{
                                  color: BOOKING_STATUS_COLORS[status].color,
                                  background:
                                    BOOKING_STATUS_COLORS[status].background,
                                  textAlign: 'center',
                                }}
                              >
                                {sentenceCase(status)}
                              </Label>
                            </TableCell>

                            <TableCell align="right">
                              <IconButton
                                size="large"
                                color="inherit"
                                onClick={(event) => handleOpenMenu(event, _id)}
                              >
                                <Iconify icon="eva:more-vertical-fill" />
                              </IconButton>
                            </TableCell>
                            <Popover
                              open={Boolean(
                                openPopover &&
                                selectedBookingRow &&
                                selectedBookingRow._id === _id
                              )}
                              anchorEl={openPopover}
                              onClose={handleCloseMenu}
                              anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                              }}
                              PaperProps={{
                                sx: {
                                  p: 1,
                                  width: 140,
                                  '& .MuiMenuItem-root': {
                                    px: 1,
                                    typography: 'body2',
                                    borderRadius: 0.75,
                                  },
                                },
                              }}
                            >
                              <MenuItem
                                onClick={() => {
                                  handleOpenModal(row);
                                  setIsNew(false);
                                }}
                              >
                                <Iconify
                                  icon="eva:eye-outline"
                                  sx={{ mr: 2 }}
                                />
                                View
                              </MenuItem>
                              {status !== 'NO_SHOW' ? (
                                <>
                                  {status !== 'APPROVED' && (
                                    <MenuItem
                                      sx={{ color: 'success.main' }}
                                      onClick={() => updateBooking('APPROVED')}
                                    >
                                      Approve
                                    </MenuItem>
                                  )}
                                  {status !== 'PENDING' && (
                                    <MenuItem
                                      sx={{ color: 'warning.main' }}
                                      onClick={() => updateBooking('PENDING')}
                                    >
                                      Pending
                                    </MenuItem>
                                  )}
                                  {status !== 'RJEECTED' && (
                                    <MenuItem
                                      sx={{ color: 'error.dark' }}
                                      onClick={() => updateBooking('REJECTED')}
                                    >
                                      Reject
                                    </MenuItem>
                                  )}
                                  {status !== 'CANCELED' && (
                                    <MenuItem
                                      sx={{ color: 'error.main' }}
                                      onClick={() => updateBooking('CANCELED')}
                                    >
                                      Cancel
                                    </MenuItem>
                                  )}
                                  {status !== 'COMPLETED' && (
                                    <MenuItem
                                      sx={{ color: 'success.dark' }}
                                      onClick={() => updateBooking('COMPLETED')}
                                    >
                                      Completed
                                    </MenuItem>
                                  )}
                                </>
                              ) : null}
                            </Popover>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                ) : (
                  ''
                )}
                {!isLoading && !filteredBookings.length && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            No Bookings
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredBookings.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <BookingModal
        createBooking={createBooking}
        timezone={config.timezone}
        isNew={isNew}
        booking={selectedBookingRow}
        services={services}
        customers={customers}
        openModal={openModal}
        onCloseModal={handleCloseModal}
      />
    </>
  );
}
