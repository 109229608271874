import React, { useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useState, useCallback, useEffect } from 'react';
import Calendar from '@toast-ui/react-calendar';
import '@toast-ui/calendar/dist/toastui-calendar.min.css';
import { sentenceCase } from 'change-case';
import {
  Paper,
  Stack,
  Table,
  MenuItem,
  Popover,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  Grid,
  TablePagination,
  Card,
  Button,
} from '@mui/material';
import { AppWidgetSummary } from '../sections/@dashboard/app';
import Label from '../components/label';
import { TableListHead } from '../sections/@dashboard/bookings';
import Scrollbar from '../components/scrollbar';
import { BOOKING_STATUS_COLORS } from '../theme/palette';
import Iconify from '../components/iconify';
import axios from '../utils/Axios';
import { TableSkeleton } from '../components/skeleton';
import {
  formatBookingDate,
  formatBookingTime,
  isTodayDate,
  isExpiredSubscription,
} from '../utils/formatTime';
import { useAuthContext } from '../auth/useAuthContext';
import BookingModal from '../sections/@dashboard/bookings/Modal';
import PricingPlans from '../sections/@dashboard/app/PricingPlans';
import dayjs from 'dayjs';

const monthNames = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
];
const TABLE_HEAD = [
  { id: 'customer.firstName', label: 'Customer', alignRight: false },
  { id: 'startDate', label: 'Date', alignRight: false },
  { id: 'service.name', label: 'Service', alignRight: false },
  { id: 'startDate', label: 'Time', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '', alignRight: false },
];

function applySortFilter(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function descendingComparator(a, b, orderBy) {
  if (orderBy.includes('.')) {
    const splitMe = orderBy.split('.');
    const first = splitMe[0];
    const second = splitMe[1];

    if (b[first][second] < a[first][second]) {
      return -1;
    }
    if (b[first][second] > a[first][second]) {
      return 1;
    }
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export default function DashboardAppPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedType, setSelectedType] = useState('calender');
  const [services, setServices] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [schedules, setSchedules] = useState([])
  const [openPopover, setOpenPopover] = useState(null);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [agencyLoaded, setAgencyLoaded] = useState(false);
  const [calenderReload, setCalenderReload] = useState(1);
  const { user } = useAuthContext();

  const [selectedBookingRow, setSelectedBookingRow] = useState(null);

  const [openModal, setOpenModal] = useState(false);
  const handleSelect = (buttonType) => {
    setSelectedType(buttonType);
  };
  const styles = (isSelected) => ({
    border: isSelected ? '1px solid gray' : '1px solid lightgray',
    backgroundColor: '#d3d3d333',
    color: 'gray',
    padding: '10px',
    margin: '5px',
    minWidth: '50px',
  });
  const handleOpenModal = (row) => {
    setOpenPopover(null);
    setOpenModal(true);
    setSelectedBookingRow(row);
  };

  const handleCloseModal = () => {
    setOpenModal(false);

    if (selectedBookingRow != null) {
      setSelectedBookingRow(null);
    }
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('startDate');
  const [viewType, setViewType] = useState('calendar');

  const {user: { _id, config, subscription } } = useAuthContext();

  const isExpired =
    subscription && isExpiredSubscription(subscription.dateExpiry);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleOpenMenu = (event, rowId) => {
    setOpenPopover(event.currentTarget);
    setSelectedBookingRow({ _id: rowId });
  };

  const handleCloseMenu = () => {
    setOpenPopover(null);
  };

  const handleRequestSort = (_, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const getBookings = useCallback(async () => {
    try {
      const [bookingsResponse, servicesResponse, customersResponse] =
        await Promise.all([
          axios.get('/bookings'),
          axios.get('/services'),
          axios.get('/customers'),
        ]);
      // update startDate and endDate to Date object using config timezone
      bookingsResponse.data.bookings.forEach(booking => {
        booking.startDate = dayjs(booking.startDate).tz(config.timezone).format("YYYY-MM-DD HH:mm:ss");
        booking.endDate = dayjs(booking.endDate).tz(config.timezone).format("YYYY-MM-DD HH:mm:ss");
      });

      console.log('bookingsResponse with timezone', bookingsResponse.data.bookings);

      setBookings(bookingsResponse.data.bookings);
      // based on all the booking get total revenue based on price 
      let total = 0;
      bookingsResponse.data.bookings.forEach(booking => {
        total += (booking.price ?? 0);
      });

      setTotalRevenue(total);

      setSchedules([
        ...bookingsResponse.data.bookings?.map(booking => ({
          id: booking._id,
          calendarId: '1',
          title: booking.service.name,
          category: 'time',
          dueDateClass: '',
          start: new Date(booking.startDate),
          end: new Date(booking.endDate),
        })),
      ])
      setTimeout(() => {
        setCalenderReload(Math.random())
      }, 100);
      setServices(servicesResponse.data.services);
      setCustomers(customersResponse.data.customers);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    if (!isExpired) {
      getBookings();
    }
  }, []);

  useEffect(() => {
    if (window.location.hostname == 'agency.flowbookings.io') {
      window.location.href = '/dashboard/agency';
    }
    setAgencyLoaded(true);
  }, []);

  const activeBookings = bookings.filter(
    ({ status }) => status === 'PENDING' || status === 'APPROVED'
  );

  const filteredBookings = applySortFilter(
    activeBookings,
    getComparator(order, orderBy)
  );

  const todayBookings = activeBookings.filter(({ startDate }) =>
    isTodayDate(startDate, config.timezone)
  );
  const calendarRef = useRef(null);

  useEffect(() => {
    // Add an example schedule directly in useEffect for demo purposes
    if (calendarRef.current) {
      const calendarInstance = calendarRef.current.getInstance();

      calendarInstance.createEvents([]);
    }
  }, []);
  const handlePrevClick = () => {
    const calendarInstance = calendarRef.current.getInstance();
    calendarInstance.prev();
    setTimeout(() => {

      setCurrentDate(calendarInstance.getDate())
    }, 200);
  };

  const handleNextClick = () => {
    const calendarInstance = calendarRef.current.getInstance();
    calendarInstance.next();
    setTimeout(() => {

      setCurrentDate(calendarInstance.getDate())
    }, 200);
  };

  const handleTodayClick = () => {
    const calendarInstance = calendarRef.current.getInstance();
    calendarInstance.today();
    setTimeout(() => {

      setCurrentDate(calendarInstance.getDate())
    }, 200);
  };
  function _getTimeTemplate(schedule, isAllDay) {
    var html = [];

    if (!isAllDay) {
      html.push("<strong>" + _getFormattedTime(schedule.start) + "</strong> ");
    }
    if (schedule.isPrivate) {
      html.push('<span class="calendar-font-icon ic-lock-b"></span>');
      html.push(" Private");
    } else {
      if (schedule.isReadOnly) {
        html.push('<span class="calendar-font-icon ic-readonly-b"></span>');
      } else if (schedule.recurrenceRule) {
        html.push('<span class="calendar-font-icon ic-repeat-b"></span>');
      } else if (schedule.attendees.length) {
        html.push('<span class="calendar-font-icon ic-user-b"></span>');
      } else if (schedule.location) {
        html.push('<span class="calendar-font-icon ic-location-b"></span>');
      }
      html.push(" " + schedule.title);
    }

    return html.join("");
  }
  const templates = {
    time: function (schedule) {
      console.log(schedule);
      return _getTimeTemplate(schedule, false);
    }
  };

  const onClickSchedule = useCallback((e) => {
    const { calendarId, id } = e.schedule;
    const el = cal.current.calendarInst.getElement(id, calendarId);

    console.log(e, el.getBoundingClientRect());
  }, []);
  const handleViewChange = useCallback(() => {
    // When the calendar view changes, update the current date state
    const calendarInstance = calendarRef.current.getInstance();
    setCurrentDate(calendarInstance.getDate()); // Update current date
  }, []);

  useEffect(() => {
    if (calendarRef.current) {
      const calendarInstance = calendarRef.current.getInstance();
      calendarInstance.on('afterRenderSchedule', handleViewChange); // Listen to view change events
    }
    return () => {
      if (calendarRef.current) {
        const calendarInstance = calendarRef.current.getInstance();
        calendarInstance.off('afterRenderSchedule', handleViewChange); // Cleanup listener
      }
    };
  }, [handleViewChange]);


  return (
    <>
      <Helmet>
        <title> Dashboard | {localStorage.getItem("companyName") ?? "FlowBookings"} </title>
      </Helmet>
      {agencyLoaded && <Container maxWidth="xl">
        {!subscription.subscriptionId && <PricingPlans user={_id} />}
        {!isExpired ? (
          <>
            <Grid container spacing={3} sx={{ mb: 5 }}>
              <Grid item xs={12} md={4}>
                <AppWidgetSummary
                  title="Bookings Today"
                  total={todayBookings.length || 0}
                  color="warning.main"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <AppWidgetSummary
                  title="Total Bookings"
                  total={bookings.length || 0}
                  color="success.main"
                />
              </Grid>
              {user.config.enablePayments ? <Grid item xs={12} md={4}>
                <AppWidgetSummary
                  title="Total Revenue"
                  total={getSymbol(user.config.currency) + " " + totalRevenue}
                  color="#3366FF"
                />
              </Grid> : <Grid item xs={12} md={4}>
                <AppWidgetSummary
                  title="Total Customers"
                  total={customers.length || 0}
                  color="#3366FF"
                />
              </Grid>}
            </Grid>

            {selectedType == 'calender' && <Card style={{ height: '100vh', padding: '1rem' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <Button
                    onClick={() => handleSelect('calender')}
                    style={styles(selectedType === 'calender')}
                  >
                    <Iconify icon="eva:keypad-fill" />
                  </Button>
                  <Button
                    onClick={() => handleSelect('table')}
                    style={styles(selectedType === 'table')}
                  >
                    <Iconify icon="eva:list-fill" />
                  </Button>
                </div>
                <div style={{ width: '300px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <button style={{ background: 'transparent', outline: 'none', border: 'none' }} onClick={handlePrevClick}><Iconify icon="eva:arrow-ios-back-outline" /></button>
                  <Typography variant="h5">
                    {monthNames[currentDate.getMonth()]}{' '}
                    {currentDate.getFullYear()}
                  </Typography>
                  <button style={{ background: 'transparent', outline: 'none', border: 'none' }} onClick={handleNextClick}><Iconify icon="eva:arrow-ios-forward-outline" /></button></div>
                <div>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleTodayClick}>
                    Today
                  </Button>
                </div>
              </div>
              <Calendar
                key={calenderReload}
                ref={calendarRef}
                usageStatistics={false}
                height="100%"
                // useCreationPopup={true}
                // template={templates}
                // useDetailPopup={true}
                view={'month'}// Change to "week" or "day" as needed
                // month={{
                //   startDayOfWeek: config.startDayOfWeek,
                // }}
                // timezones={[
                //   {
                //     timezoneOffset: 540,
                //     displayLabel: 'GMT+09:00',
                //     tooltip: 'Seoul',
                //   },
                //   {
                //     timezoneOffset: -420,
                //     displayLabel: 'GMT-08:00',
                //     tooltip: 'Los Angeles',
                //   },
                // ]}
                onClickEvent={(e) => {
                  console.log(e);
                  let id = e.event.id;
                  let booking = bookings.find(booking => booking._id == id);
                  handleOpenModal(booking);
                }}
                calendars={[
                  {
                    id: '1',
                    name: 'Personal',
                    bgColor: '#9e5fff',
                    borderColor: '#9e5fff',
                  },
                ]}
                isReadOnly={true}
                events={schedules}
              />
            </Card>}

            {selectedType == 'table' && <Card>
              <div style={{ display: 'flex', justifyContent: 'space-between', padding: '1rem' }}>
                <div>
                  <Button
                    onClick={() => handleSelect('calender')}
                    style={styles(selectedType === 'calender')}
                  >
                    <Iconify icon="eva:keypad-fill" />
                  </Button>
                  <Button
                    onClick={() => handleSelect('table')}
                    style={styles(selectedType === 'table')}
                  >
                    <Iconify icon="eva:list-fill" />
                  </Button>
                </div>
              </div>
              <Scrollbar>
                <TableContainer sx={{ minWidth: 100 }}>
                  <Table>
                    <TableListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={0}
                      onRequestSort={handleRequestSort}
                    />
                    {isLoading && [...Array(5)].map(() => <TableSkeleton />)}
                    {!isLoading && filteredBookings.length ? (
                      <TableBody>
                        {filteredBookings
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row) => {
                            const {
                              _id,
                              customer,
                              startDate,
                              status,
                              service,
                            } = row;
                            return (
                              <TableRow hover key={_id} tabIndex={-1}>
                                <TableCell component="th" scope="row">
                                  <Stack
                                    direction="column"
                                    alignItems="left"
                                    spacing={0.5}
                                  >
                                    <Typography variant="subtitle2" noWrap>
                                      {customer.firstName} {customer.lastName}
                                    </Typography>
                                    <Typography variant="subtitle3">
                                      {customer.email}
                                    </Typography>
                                  </Stack>
                                </TableCell>

                                <TableCell align="left">
                                  {formatBookingDate(
                                    startDate,
                                    config.timezone
                                  )}
                                </TableCell>
                                <TableCell align="left">
                                  {service.name}
                                </TableCell>
                                <TableCell align="left">
                                  {formatBookingTime(
                                    startDate,
                                    config.timezone
                                  )}
                                </TableCell>

                                <TableCell align="left">
                                  <Label
                                    sx={{
                                      color:
                                        BOOKING_STATUS_COLORS[status].color,
                                      background:
                                        BOOKING_STATUS_COLORS[status]
                                          .background,
                                      textAlign: 'center',
                                    }}
                                  >
                                    {sentenceCase(status)}
                                  </Label>
                                </TableCell>

                                <TableCell align="right">
                                  <IconButton
                                    size="large"
                                    color="inherit"
                                    onClick={(event) =>
                                      handleOpenMenu(event, _id)
                                    }
                                  >
                                    <Iconify icon="eva:more-vertical-fill" />
                                  </IconButton>
                                </TableCell>
                                <Popover
                                  open={Boolean(
                                    openPopover &&
                                    selectedBookingRow &&
                                    selectedBookingRow._id === _id
                                  )}
                                  anchorEl={openPopover}
                                  onClose={handleCloseMenu}
                                  anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                  }}
                                  PaperProps={{
                                    sx: {
                                      p: 1,
                                      width: 140,
                                      '& .MuiMenuItem-root': {
                                        px: 1,
                                        typography: 'body2',
                                        borderRadius: 0.75,
                                      },
                                    },
                                  }}
                                >
                                  <MenuItem
                                    onClick={() => handleOpenModal(row)}
                                  >
                                    <Iconify
                                      icon="eva:eye-outline"
                                      sx={{ mr: 2 }}
                                    />
                                    View
                                  </MenuItem>
                                </Popover>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    ) : (
                      ''
                    )}
                    {!filteredBookings.length && !isLoading && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <Paper
                              sx={{
                                textAlign: 'center',
                              }}
                            >
                              <Typography variant="h6" paragraph>
                                No Upcoming Bookings
                              </Typography>
                            </Paper>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={activeBookings.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>}
          </>
        ) : null}{/* <Calendar usageStatistics={false} /> */}
      </Container>}

      <BookingModal
        booking={selectedBookingRow}
        services={services}
        customers={customers}
        openModal={openModal}
        onCloseModal={handleCloseModal}
      />
    </>
  );
}

export const getSymbol = (currency) => {
  const currencySymbolMap = {
    AED: "AED",
    AFN: "AFN",
    ALL: "ALL",
    AMD: "AMD",
    ANG: "ANG",
    AOA: "AOA",
    ARS: "AR$",
    AUD: "A$",
    AWG: "AWG",
    AZN: "AZN",
    BAM: "BAM",
    BBD: "BBD",
    BDT: "BDT",
    BGN: "BGN",
    BHD: "BHD",
    BIF: "BIF",
    BMD: "BMD",
    BND: "BND",
    BOB: "BOB",
    BRL: "R$",
    BSD: "BSD",
    BTN: "BTN",
    BWP: "BWP",
    BYN: "BYN",
    BZD: "BZD",
    CAD: "CA$",
    CDF: "CDF",
    CHF: "CHF",
    CLP: "CL$",
    CNY: "CN¥",
    COP: "CO$",
    CRC: "CRC",
    CUP: "CUP",
    CVE: "CVE",
    CZK: "CZK",
    DJF: "DJF",
    DKK: "DKK",
    DOP: "DOP",
    DZD: "DZD",
    EGP: "EGP",
    ERN: "ERN",
    ETB: "ETB",
    EUR: "€",
    FJD: "FJD",
    FKP: "FKP",
    GBP: "£",
    GEL: "GEL",
    GHS: "GHS",
    GIP: "GIP",
    GMD: "GMD",
    GNF: "GNF",
    GTQ: "GTQ",
    GYD: "GYD",
    HKD: "HK$",
    HNL: "HNL",
    HRK: "HRK",
    HTG: "HTG",
    HUF: "HUF",
    IDR: "IDR",
    ILS: "₪",
    INR: "₹",
    IQD: "IQD",
    IRR: "IRR",
    ISK: "ISK",
    JMD: "JMD",
    JOD: "JOD",
    JPY: "¥",
    KES: "KES",
    KGS: "KGS",
    KHR: "KHR",
    KMF: "KMF",
    KPW: "KPW",
    KRW: "₩",
    KWD: "KWD",
    KYD: "KYD",
    KZT: "KZT",
    LAK: "LAK",
    LBP: "LBP",
    LKR: "LKR",
    LRD: "LRD",
    LSL: "LSL",
    LYD: "LYD",
    MAD: "MAD",
    MDL: "MDL",
    MGA: "MGA",
    MKD: "MKD",
    MMK: "MMK",
    MNT: "MNT",
    MOP: "MOP",
    MRU: "MRU",
    MUR: "MUR",
    MVR: "MVR",
    MWK: "MWK",
    MXN: "MX$",
    MYR: "MYR",
    MZN: "MZN",
    NAD: "NAD",
    NGN: "NGN",
    NIO: "NIO",
    NOK: "NOK",
    NPR: "NPR",
    NZD: "NZ$",
    OMR: "OMR",
    PAB: "PAB",
    PEN: "PEN",
    PGK: "PGK",
    PHP: "₱",
    PKR: "PKR",
    PLN: "PLN",
    PYG: "PYG",
    QAR: "QAR",
    RON: "RON",
    RSD: "RSD",
    RUB: "RUB",
    RWF: "RWF",
    SAR: "SAR",
    SBD: "SBD",
    SCR: "SCR",
    SDG: "SDG",
    SEK: "SEK",
    SGD: "SGD",
    SHP: "SHP",
    SLE: "SLE",
    SOS: "SOS",
    SRD: "SRD",
    STN: "STN",
    SVC: "SVC",
    SYP: "SYP",
    SZL: "SZL",
    THB: "฿",
    TJS: "TJS",
    TMT: "TMT",
    TND: "TND",
    TOP: "TOP",
    TRY: "TRY",
    TTD: "TTD",
    TWD: "NT$",
    TZS: "TZS",
    UAH: "UAH",
    UGX: "UGX",
    USD: "$",
    UYU: "UYU",
    UZS: "UZS",
    VES: "VES",
    VND: "₫",
    VUV: "VUV",
    WST: "WST",
    XAF: "FCFA",
    XCD: "EC$",
    XDR: "XDR",
    XOF: "CFA",
    XPF: "CFPF",
    YER: "YER",
    ZAR: "ZAR",
    ZMW: "ZMW",
    ZWL: "ZWL"
  };

  return currencySymbolMap[currency] ?? currency;
}