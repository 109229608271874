import { useCallback, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Grid,
  Card,
  Stack,
  Typography,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from '../../../components/iconify';
import FormProvider, {
  RHFTextField,
  RHFUploadAvatar,
} from '../../../components/hook-form';
import axios from '../../../utils/Axios';

let currentImage = null;

export default function Profile({ user, updateUser }) {
  const defaultValues = {
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    email: user?.email || '',
    avatar: user?.avatar || '',
    phoneNumber: user?.phoneNumber || '',
    companyName: user?.companyName || '',
    password: '',
    websiteURI: user?.websiteURI || '',
  };

  const methods = useForm({ defaultValues });
  const [image, setImage] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const {
    setValue,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    reset(defaultValues);
    return () => {
      currentImage = '';
    };
  }, [user]);

  const onSubmit = async (data) => {
    const shouldUploadImage = image && !currentImage;
    if (shouldUploadImage) {
      const response = await axios.post('/upload');
      const signData = response.data;

      const formData = new FormData();
      formData.append('file', image);

      const url = `https://api.cloudinary.com/v1_1/de7fg4j42/image/upload?api_key=437499416445219&timestamp=${signData.timestamp}&signature=${signData.signature}&discard_original_filename=true&folder=${signData.folder}`;

      const imageResponse = await fetch(url, {
        method: 'POST',
        body: formData,
      });

      const imageJson = await imageResponse.json();
      if (imageJson.url) {
        currentImage = imageJson.url;
      } else {
        throw new Error(
          "Couldn't upload the preview image check the image size or try uploading again."
        );
      }
    }

    await updateUser({
      ...data,
      avatar: !currentImage ? data.avatar : currentImage,
    });
  };

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        currentImage = null;
        setValue('avatar', newFile, { shouldValidate: true });
        setImage(file);
      }
    },
    [setValue]
  );

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Card sx={{ py: 10, px: 3, textAlign: 'center' }}>
            <RHFUploadAvatar
              name="avatar"
              maxSize={3145728}
              onDrop={handleDrop}
              helperText={
                <Typography
                  variant="caption"
                  sx={{
                    mt: 2,
                    mx: 'auto',
                    display: 'block',
                    textAlign: 'center',
                    color: 'text.secondary',
                  }}
                >
                  Allowed *.jpeg, *.jpg, *.png, *.gif
                  <br /> max size of {3145728}
                </Typography>
              }
            />
          </Card>
        </Grid>

        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              <RHFTextField name="firstName" label="First Name" />
              <RHFTextField name="lastName" label="Last Name" />
              <RHFTextField name="email" label="Email Address" disabled />
              <RHFTextField
                name="password"
                type={showPassword ? 'text' : 'password'}
                label="New Password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        <Iconify
                          icon={
                            showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'
                          }
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <RHFTextField name="phoneNumber" label="Phone Number" type="tel" />
              <RHFTextField name="companyName" label="Company" />
              <RHFTextField name="websiteURI" label="Website" type="url" />
            </Box>

            <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton
                type="submit"
                size="medium"
                variant="contained"
                loading={isSubmitting}
              >
                Save Changes
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
