import { Box, Card, Grid, Typography, Select, MenuItem, Paper, FormControl } from '@mui/material'
import axios from '../utils/Axios';
import React, { useCallback, useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts';
import { Helmet } from 'react-helmet-async';

const Reports = () => {
    const [bookings, setBookings] = useState([]);

    const getBookings = useCallback(async () => {
        try {
            const [bookingsResponse] = await Promise.all([axios.get('/bookings')]);
            setBookings(bookingsResponse.data.bookings);
        } catch (error) {
            console.error('getBookings -> error', error);
        }
    }, []);

    useEffect(() => {
        getBookings();
    }, [getBookings]);

    return (
        <div>
            <Helmet>
            <title>Reports | {localStorage.getItem("companyName") ?? "FlowBookings"}</title>
            </Helmet>
            <Box
                display="grid"
                columnGap={3}
                gridTemplateColumns={{
                    xs: 'repeat(1, 1fr)',
                    sm: 'repeat(1, 65%) 1fr',
                }}
            >
                <div>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Card sx={{ p: 3, height: '100%' }}>
                                <BarChart heading="Booking Reports" bookings={bookings} type="bookings" />
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <Card sx={{ p: 3, height: '100%' }}>
                                <BarChart heading="Revenue Reports" bookings={bookings} type="revenue" />
                            </Card>
                        </Grid>
                    </Grid>
                </div>
                <div>
                    <Card sx={{ p: 3, height: '100%' }}>
                        <Typography variant="h4" sx={{ mb: 3 }}>
                            Quick Stats
                        </Typography>
                        <QuickStats bookings={bookings} />
                    </Card>
                </div>
            </Box>
        </div>
    );
};

const BarChart = ({ heading, bookings, type }) => {
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

    const processBookingData = useCallback((year) => {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const monthlyData = new Array(12).fill(0);
        const monthlyRevenue = new Array(12).fill(0);

        bookings.forEach(booking => {
            const bookingDate = new Date(booking.startDate);
            const bookingYear = bookingDate.getFullYear();
            
            if (bookingYear === year) {
                const month = bookingDate.getMonth();
                monthlyData[month]++;
                monthlyRevenue[month] += booking.price || 0;
            }
        });

        if (type === 'bookings') {
            return [{
                name: 'Bookings',
                data: monthlyData
            }];
        } else {
            return [{
                name: 'Revenue',
                data: monthlyRevenue
            }];
        }
    }, [bookings, type]);

    const [options, setOptions] = useState({
        chart: {
            type: 'bar',
            height: 350,
            toolbar: {
                show: true,
                tools: {
                    download: '<img src="../../download-icon.png" alt="download" />',
                },
            }
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        },
        yaxis: {
            title: {
                text: type === 'revenue' ? '$ (amount)' : 'Number of Bookings'
            }
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return type === 'revenue' ? `$ ${val}` : val;
                }
            }
        }
    });

    const [series, setSeries] = useState([]);

    useEffect(() => {
        if (bookings.length > 0) {
            setSeries(processBookingData(selectedYear));
        }
    }, [bookings, selectedYear, processBookingData]);

    return (
        <div>
            <div className="removeLegend" style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '16px',
            }}>
                <Typography variant="h4" sx={{ mb: 3 }}>
                    {heading}
                </Typography>
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <Select
                    labelId="demo-select-small-label"
                    size="small"
                    value={selectedYear}
                    onChange={(e) => setSelectedYear(e.target.value)}
                >
                    <MenuItem value={2023}>2023</MenuItem>
                    <MenuItem value={2024}>2024</MenuItem>
                    <MenuItem value={2025}>2025</MenuItem>
                </Select>
                </FormControl>
            </div>
            <div id="chart">
                <ReactApexChart 
                    options={options} 
                    series={series} 
                    type="bar" 
                    height={350} 
                    />
            </div>
        </div>
    );
};

const QuickStats = ({ bookings }) => {
    const [stats, setStats] = useState([
        { label: 'Total', value: 0, color: '#00bcd4' },
        { label: 'Pending', value: 0, color: '#ffc107' },
        { label: 'Approved', value: 0, color: '#4caf50' },
        { label: 'Completed', value: 0, color: '#4caf50' },
        { label: 'Canceled', value: 0, color: '#f44336' },
        { label: 'No Show', value: 0, color: '#bdbdbd' },
    ]);

    useEffect(() => {
        if (bookings.length > 0) {
            const newStats = stats.map(stat => {
                const count = bookings.filter(booking => {
                    if (stat.label === 'Total') return true;
                    // Handle 'No Show' case specifically
                    if (stat.label === 'No Show') {
                        return booking.status === 'NO_SHOW';
                    }
                    return booking.status === stat.label.toUpperCase();
                }).length;
                return { ...stat, value: count };
            });
            setStats(newStats);
        }
    }, [bookings]);

    return (
        <Grid container spacing={2}>
            {stats.map((stat) => (
                <Grid item xs={12} sm={12} md={12} key={stat.label}>
                    <Paper style={{
                        padding: '16px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}>
                        <Typography variant="subtitle1" style={{
                            marginRight: '8px',
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <Box style={{ 
                                backgroundColor: stat.color, 
                                width: '12px', 
                                height: '12px', 
                                borderRadius: '50%', 
                                marginRight: '8px' 
                            }} /> 
                            {stat.label}
                        </Typography>
                        <Typography variant="subtitle1">{stat.value}</Typography>
                    </Paper>
                </Grid>
            ))}
        </Grid>
    );
};


export default Reports
